<template>
  <div>
    <div id="container" style="width: 100%;height: 100vh"/>
  </div>
</template>
<script>
//在需要跳转的页面 引入插件
export default {
  data() {
    return {
      name: '',
      type: '',
      vehicle_id: this.$route.query.vehicle_id,
      vehicle_name: this.$route.query.vehicle_name
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    getList(name, type) {
      alert()
      console.log(name, type)
    },
    init() {
      const that = this
      const param = {
        appkey: 'coqyKLsOWZvP',
        appsecret: '2dwzDhVYJns04S9LMHrWujPQpBalvZkx'
      }
      const viewer = new AMRT.Viewer('container', /*容器dom或者dom对应的id*/ param)
      viewer.loadModel('1751443271672008704')
      viewer.hotSpotManager.addSpriteLabel({
        position: [-1.703038788064755, 0.40705835209737495, 4.494966421968084],
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('一轴右', '1r1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {x: -1.5820746196003075, y: 0.44880021411693916, z: 2.25594037400218},
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('二轴右', '2r1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {x: -1.5820746196003075, y: 0.41482848658338756, z: 1.0945951940194343},
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('三轴右', '3r1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": -1.5820754111499724,
          "y": 0.42231000957402354,
          "z": -3.170558242638544
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('四轴右', '4r1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": -1.5820754111499724,
          "y": 0.3858268762841939,
          "z": -4.297563784413956
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('五轴右', '5r1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": -1.5820754111499724,
          "y": 0.4342035006011076,
          "z": -5.291933782803886
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('六轴右', '5r1')
        }
      })

      //左边
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": 0.5537017122598278,
          "y": 0.4263417421385465,
          "z": 4.464654818874935
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('一轴左', '1l1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": 0.5228303727940651,
          "y": 0.27484848505952897,
          "z": 2.2486721516964474
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          console.log(label)
          that.getList('二轴左', '2l1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": 0.4424006094872168,
          "y": 0.44582567735532175,
          "z": 1.024733747424838
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('三轴左', '3l1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": 0.4675929947156736,
          "y": 0.37919421359600647,
          "z": -3.284155711416881
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('四轴左', '4l1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": 0.43845011837190473,
          "y": 0.4333558887664226,
          "z": -4.266241808375615
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('五轴左', '5l1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": 0.43900161555045614,
          "y": 0.3791629288049392,
          "z": -5.30141881018438
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('六轴左', '6l1')
        }
      })
      viewer.hotSpotManager.addSpriteLabel({
        position: {
          "x": 0.3484054598163224,
          "y": 1.0840426175578282,
          "z": 3.452545816112757
        },
        content: '<span style="padding: 15px;background: #00a0e9"></span>',
        onclick(label) {//点击事件
          that.getList('油箱', 'yx')
        }
      })
    },
  }
}
</script>
<style scoped lang="less">

</style>
